import React from "react";
import { useApiLoading } from "react-reqq";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";

import { encryptText } from "modules/common/helper";
import FormInput from "modules/common/forms/FormInput";
import FormInputPassword from "modules/common/forms/FormInputPassword";
import LoginAvatar from "modules/partial/components/LoginAvatar";
import { LogoWhite } from "modules/common/components/AppIconLogo";

import * as actions from "../actions";
import * as c from "../constants";

const validationSchema = yup.object({
  email_address: yup
    .string()
    .required("Email Address is required")
    .email("Email Address must be a valid email"),
  password: yup.string().required("Password is required"),
});

function Login() {
  const isLoading = useApiLoading(c.LOGIN, "post");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    actions.login({
      email_address: encryptText(data.email_address),
      password: encryptText(data.password),
    });
  };
  return (
    <div className="h-screen w-screen bg-login flex">
      <form
        className="h-full bg-cover bg-center form-background w-1/3 p-10 flex flex-col justify-center"
        onSubmit={handleSubmit(onSubmit)}
        style={{ borderRadius: "0 50px 50px 0" }}
      >
        <h1 className="text-3xl font-medium">Login</h1>
        <p className="mt-4 text-gray-400 text-left">
          Use your admin credentials to login to DST Core
        </p>
        <div className="mt-6">
          <FormInput
            name="email_address"
            register={register}
            label="Email Address"
            placeholder="name@domain.com"
            error={errors.email_address}
            required
          />
          <FormInputPassword
            name="password"
            register={register}
            placeholder="Password"
            label="Password"
            error={errors.password}
            required
          />
        </div>
        <div className="flex mt-4">
          <button
            className="btn primary rounded-full w-full mx-auto"
            type="submit"
            disabled={isLoading}
          >
            Log In
          </button>
        </div>
      </form>
      <div className="flex items-center justify-center flex-col w-2/3 px-2 text-white">
        <LogoWhite className="w-48 py-5" />
        <div className="w-full py-3">
          <LoginAvatar />
        </div>
        <h1 className="font-medium mt-3 mb-3 text-4xl text-center">
          ADMIN PORTAL
        </h1>
        <p className="w-full py-5 mt-3 mb-3 text-center">
          Version 4.2.4 ©{dayjs().format("YYYY")} DST
        </p>
      </div>
    </div>
  );
}

Login.propTypes = {};

export default Login;

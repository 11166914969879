import { configureApi } from "react-reqq";
import Cookie from "js-cookie";
import { get } from "lodash";
import { toast } from "react-toastify";
import history from "./history";

const store = configureApi({
  endpoint: process.env.REACT_APP_END_POINT,
  requestHeaders: () => {
    const token = Cookie.get("_token");
    if (!token) return {};
    return {
      Authorization: `Bearer ${token}`,
    };
  },
  timeout: 180000,
  // cacheStorage: "WEBSQL",
  onError: (err) => {
    const err_message =
      err?.response?.errors?.[0]?.message || err?.response?.message;

    if (err_message === "Token expired.") {
      toast.info("Please re-login to continue.");
      history.push("/logout");
    }

    if (err.status === 422) {
      const message = get(
        err,
        `response.errors.${Object.keys(get(err, "response.errors"))[0]}.0`
      );
      toast.error(message || err_message || "Unprocessable Entity!");
      return;
    }
    if (err.status === 400) {
      toast.error(err_message || "Bad Request!");
      return;
    }
    if (err.status === 401) {
      const message =
        get(err, "response.errors.message") || get(err, "response.message");
      toast.error(message);
      return;
    }
    if (err.status === 500) {
      toast.error(err_message || "Internal Server Error!");
      return;
    }
    if (err.status === 403) {
      toast.error(err_message || "Forbidden!");
      if (
        get(err, "response.error") === "token_expired" ||
        get(err, "response.error") === "token_invalid"
      )
        history.push("/logout");
      return;
    }
    if (err.status === 0) {
      toast.error("Unable to connect to web service");
      return;
    }
    console.warn("unhandled error", err); // eslint-disable-line
  },
});

export default store;

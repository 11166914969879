import React, { useCallback } from "react";
import Cookie from "js-cookie";
import { req, useApiGet, useApiLoading } from "react-reqq";
import { get } from "lodash";

import store from "setup/store";
import * as c from "./constants";
import * as actions from "./actions";

export const useAuth = () => {
  const auth = useApiGet(c.AUTHENTICATION, {});
  React.useEffect(() => {
    const token = Cookie.get("_token");
    const type = Cookie.get("_userType");
    req.set(c.AUTHENTICATION, {
      isAuthenticated: !!token,
      type,
    });
  }, []);
  return [
    typeof auth.isAuthenticated === "boolean",
    auth.isAuthenticated,
    auth.type,
  ];
};

export const withAclReq = (requestByType, defaultRequest) => {
  const state = store.getState().api;
  const type = state[c.AUTHENTICATION]?.type;
  return {
    ...defaultRequest,
    ...requestByType(state)[type],
  };
};

export const useAccountAttributes = () => {
  const data = useApiGet(c.PROFILE, {});
  const isLoading = useApiLoading(c.PROFILE, "get");
  return [get(data, "attributes", {}), isLoading];
};

export const useChangeUserPassword = () => {
  const changeUserPassword = useCallback(actions.changeUserPassword, []);
  const isLoading = useApiLoading(c.CHANGE_PASSWORD, "put");

  return [changeUserPassword, isLoading];
};

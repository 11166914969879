import React from "react";
import dayjs from "dayjs";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import customParseFormat from "dayjs/plugin/customParseFormat";

import App from "App";
import store from "setup/store";
import history from "setup/history";
import * as serviceWorker from "serviceWorker";

import "assets/styles/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

dayjs.extend(customParseFormat);

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

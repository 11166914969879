import React from "react";
import PropTypes from "prop-types";

import whiteLogo from "assets/img/iconLogos/dst-logo-white.svg";
import purpleLogo from "assets/img/iconLogos/dst-logo-purple.svg";
import whiteIcon from "assets/img/iconLogos/dst-icon-white.svg";
import purpleIcon from "assets/img/iconLogos/dst-icon-purple.svg";

export function LogoWhite({ className }) {
  return <img className={className} src={whiteLogo} alt="brand" />;
}

LogoWhite.propTypes = {
  className: PropTypes.string.isRequired,
};

export function LogoPurple({ className }) {
  return <img className={className} src={purpleLogo} alt="brand" />;
}

LogoPurple.propTypes = {
  className: PropTypes.string.isRequired,
};

export function IconWhite({ className }) {
  return <img className={className} src={whiteIcon} alt="brand" />;
}

IconWhite.propTypes = {
  className: PropTypes.string.isRequired,
};

export function IconPurple({ className }) {
  return <img className={className} src={purpleIcon} alt="brand" />;
}

IconPurple.propTypes = {
  className: PropTypes.string.isRequired,
};

import moment from "moment";
import { isEmpty, forOwn, get, omitBy, takeRight, uniq } from "lodash";
import dayjs from "dayjs";
import { AES } from "crypto-js";

export const formatCurrency = (
  number,
  currency = "BND",
  currencyDisplay = "narrowSymbol"
) =>
  Intl.NumberFormat("en-BN", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    currencyDisplay,
  })
    .format(parseNumber(number, 0))
    .replace("$", "B$");

export const formatPercentage = (number) =>
  Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(parseNumber(number) / 100);

export const placeValueFormatter = (num = 0) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const parseNumber = (str, default_value = false) => {
  const v = parseFloat(`${str}`.replace(/,/g, ""));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return typeof default_value !== "boolean" ? default_value : str;
  return v;
};

export const formatNumber = (v, decimal = 2) => {
  try {
    const n = parseNumber(v);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(n)) return v;
    return n.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  } catch (err) {
    return v;
  }
};

export const formatDate = (date, format = "MM/DD/YYYY", defaultValue = "-") => {
  if (!date) return defaultValue;
  const d = new Date(date);
  if (d.toString() === "Invalid Date") return defaultValue;
  return moment(d).format(format);
};

export const transformIncluded = (x, included) => {
  if (!included || isEmpty(included)) return x;
  const rowIncluded = {};
  forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          (z) =>
            included.find(
              (y) => y.type === get(z, "type") && y.id === get(z, "id")
            ) || {}
        )
      : included.find(
          (y) => y.type === get(v, "data.type") && y.id === get(v, "data.id")
        ) || {};
  });
  const { links, relationships, type, ...rest } = x;
  return { ...rest, included: rowIncluded };
};

export const storage = {
  get: (key, defaultValue = false) => {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return defaultValue;
    }
  },
  set: (key, value) => {
    const newValue = JSON.stringify(value);
    sessionStorage.setItem(key, newValue);
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
};

export const removeNull = (obj) =>
  omitBy(obj, (x) => typeof x === "undefined" || x === null);

export const removeEmpty = (obj) => omitBy(obj, (x) => isEmpty(`${x}`));

export const abbrNum = (num, dPlaces) => {
  let number = num;
  const decPlaces = 10 ** dPlaces;
  const abbrev = ["k", "M", "B", "T"];
  for (let i = abbrev.length - 1; i >= 0; i -= 1) {
    const size = 10 ** ((i + 1) * 3);
    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i += 1;
      }
      number += abbrev[i];
      break;
    }
  }
  return number;
};

export const generatePasscode = (length = 10) => {
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; i += 1) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const mobileNumberSeparator = (str = "") => {
  if (str === null) return str;
  return [...str]
    .map((char, i) => {
      if (i === 2 || i === 5) {
        return `${char} `;
      }
      return char;
    })
    .join("");
};

export const formatToTitleCase = (string) =>
  (string || "")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");

export const formatedSelectOption = (
  list = [],
  value = "id",
  label = "name",
  isLabelTitleCase = false,
  isValueTitleCase = false
) => {
  const newData = [];
  list.forEach((item) => {
    newData.push({
      value: isValueTitleCase
        ? formatToTitleCase(get(item, `${value}`).toUpperCase())
        : get(item, `${value}`),
      label: isLabelTitleCase
        ? formatToTitleCase(get(item, `${label}`))
        : get(item, `${label}`),
      data: item,
    });
  });
  return newData;
};

export const formatMobileNumber = (mobile_number) =>
  `673${takeRight(mobile_number, 7).join("")}`;

export const formatFormFieldMobileNumber = (mobile_number) =>
  `(+673)${takeRight(mobile_number, 7).join("")}`;

export const objectToString = (obj) => {
  try {
    return JSON.stringify(obj);
  } catch (error) {
    return "";
  }
};

export const stringToObject = (str) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return "";
  }
};

const formatMinutes = (minutes) => {
  let h = Math.floor(minutes / 60);
  let m = minutes % 60;
  m = m < 10 ? "0" + m : m;
  return h + ":" + m;
};

export const generateTimeWithInterval = (interval) => {
  const time = [];
  const timeSlot = Math.floor(1440 / interval);

  for (let i = 0; i <= timeSlot; i++) {
    const rawMinutes = interval * i;
    const formattedMinutes = formatMinutes(rawMinutes);
    time.push(dayjs(`01/01/2022 ${formattedMinutes}`).format("h:mm A"));
  }
  return uniq(time);
};

export const encryptText = (val) => {
  return AES.encrypt(
    val,
    process.env.REACT_APP_PASSWORD_KEY || "G9AH9CTYRB1DQR9K"
  ).toString();
};

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Landing from "modules/partial/containers/Landing";
import Login from "modules/auth/containers/Login";

function Public() {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route path="/login" component={Login} />
      <Route path="/logout">
        <Redirect to="/" />
      </Route>
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
}

Public.propTypes = {};

export default Public;

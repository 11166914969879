import Cookie from "js-cookie";
import { toast } from "react-toastify";
import { req } from "react-reqq";
import { get } from "lodash";

import { encryptText } from "modules/common/helper";
import { storage, transformIncluded } from "modules/common/helper";
import * as c from "./constants";

export const login = (payload) => {
  req.post({
    key: c.LOGIN,
    url: "/api/v2/user/admin/login",
    payload,
    onSuccess: (res) => {
      toast(get(res, "response.message") || "Logged in!");
      const token = get(res, "response.token");
      Cookie.set("_token", token);
      req.set(c.AUTHENTICATION, {
        isAuthenticated: true,
      });
    },
  });
};

export const logout = () => {
  req.post({
    key: c.LOGOUT,
    url: "/api/v2/user/admin/logout",
  });
  Cookie.remove("_token");
  req.reset();
  req.clearCache();
  req.set(c.AUTHENTICATION, {
    isAuthenticated: false,
  });
};

export const getAdminProfile = (ignoreCache = true) => {
  const cachedProfile = storage.get("profile");
  if (cachedProfile && !ignoreCache) {
    req.set(c.PROFILE, cachedProfile);
    return;
  }
  req.get({
    key: c.PROFILE,
    url: "/api/v2/user/admin/profile",
    transform: (res) => {
      const newData = transformIncluded(res.data || {}, res.included || []);
      storage.set("profile", newData);
      return newData;
    },
  });
};

export const changeUserPassword = (payload, callback) => {
  req.put({
    key: c.CHANGE_PASSWORD,
    url: "/api/v2/user/auth/change_password",
    payload: {
      old_password: encryptText(payload?.old_password),
      password: encryptText(payload?.password),
      password_confirmation: encryptText(payload?.password_confirmation),
    },
    onSuccess: () => {
      toast.success("Password changed successfully.");
      if (callback) callback();
    },
  });
};

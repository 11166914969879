import React, { useState } from "react";
import PropTypes from "prop-types";

function FormPassword({ name, register, label, placeholder, error, required }) {
  const [inputType, setInputType] = useState("password");
  const [isReadOnly, setIsReadOnly] = useState(true);

  const handleTogglePassword = () => {
    if (inputType === "password") setInputType("text");
    else setInputType("password");
  };

  return (
    <div className="flex flex-col items-start">
      {label && (
        <label htmlFor={name} className="text-gray-700 font-medium text-sm">
          {label}
          {required && <span className="text-red-600 mr-1">*</span>}
        </label>
      )}
      <div className="flex items-center relative w-full mt-2">
        <input
          type={inputType}
          className={`text-gray-900 w-full p-3 rounded-xl border bg-white text-sm focus:outline-none ${
            error ? "border-red-500" : ""
          }`}
          placeholder={placeholder}
          autoComplete="off"
          readOnly={isReadOnly}
          onFocus={() => setIsReadOnly(false)}
          {...register(name)}
        />
        <button
          type="button"
          className="absolute right-0 top-1/2 mr-4 text-sm text-gray-600 hover:text-primary-600 transform -translate-y-1/2"
          onClick={handleTogglePassword}
        >
          {inputType === "password" ? "Show" : "Hide"}
        </button>
      </div>
      <small className={`text-xs text-red-500 ${!error ? "invisible" : ""}`}>
        <i className="fa fa-exclamation-circle" /> {error?.message}
      </small>
    </div>
  );
}

FormPassword.defaultProps = {
  label: "",
  placeholder: "",
  error: null,
  required: false,
};

FormPassword.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.instanceOf(Object),
  required: PropTypes.bool,
};

export default FormPassword;

import React from "react";
import PropTypes from "prop-types";

function FormInput({
  name,
  register,
  label,
  error,
  leftIcon,
  rightIcon,
  required,
  placeholder,
  disabled,
  readOnly,
  maxLength,
}) {
  return (
    <div className="flex flex-col items-start">
      {label && (
        <label htmlFor={name} className="text-gray-700 font-medium text-sm">
          {label}
          {required && <span className="text-red-600 mr-1">*</span>}
        </label>
      )}
      <div className="flex items-center relative w-full mt-2">
        <span className="absolute left-0 ml-4 ">{leftIcon}</span>
        <input
          placeholder={placeholder}
          className={`text-gray-900 w-full p-3 rounded-xl border bg-white text-sm focus:outline-none 
          ${error ? "border-red-500" : ""}
          ${rightIcon ? "pr-10" : "'"}
          ${leftIcon ? "pl-10" : ""}
          ${disabled || readOnly ? "bg-gray-100" : ""} `}
          {...register(name)}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
        />
        <span className="absolute px-4 right-0">{rightIcon}</span>
      </div>
      <small className={`text-xs text-red-500 ${!error ? "invisible" : ""}`}>
        <i className="fa fa-exclamation-circle" /> {error?.message}
      </small>
    </div>
  );
}

FormInput.defaultProps = {
  label: "",
  leftIcon: null,
  rightIcon: null,
  required: false,
  error: null,
  placeholder: "",
  disabled: false,
  readOnly: false,
  maxLength: null,
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  label: PropTypes.string,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  error: PropTypes.instanceOf(Object),
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default FormInput;

import React from "react";

import { LogoWhite } from "modules/common/components/AppIconLogo";

function Splash() {
  return (
    <div className="fixed h-screen w-screen flex bg-brand top-0 left-0 z-50 ">
      <div className="m-auto">
        <LogoWhite className="animate-pulse w-72" />
      </div>
    </div>
  );
}

export default Splash;

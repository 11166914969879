import React, { Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuth } from "modules/auth/hooks";
import { ModalProvider } from "modules/common/components/Modal";
import ErrorBoundary from "modules/partial/containers/ErrorBoundary";
import Public from "./Public";
import Splash from "modules/partial/containers/Splash";

const Private = lazy(() => import("./Private"));

function App() {
  const [isAppReady, isAuthenticated] = useAuth();
  if (!isAppReady) return null;
  return (
    <ErrorBoundary>
      <ModalProvider>
        <ToastContainer />
        {isAuthenticated ? (
          <Suspense fallback={<Splash />}>
            <Private />
          </Suspense>
        ) : (
          <Public />
        )}
      </ModalProvider>
    </ErrorBoundary>
  );
}

export default App;
